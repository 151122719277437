<template>
	<footer class="text-center bg-white sm:px-12 md:text-left" aria-labelledby="footer-heading">
		<h2 id="footer-heading" class="sr-only">
			Footer
		</h2>
		<slot>
			<div class="relative max-w-6xl p-8 mx-auto sm:px-6 lg:py-12 lg:px-8">
				<div class="flex flex-col md:flex-row md:justify-between md:space-x-4">
					<div class="flex flex-col items-center max-w-xs mx-auto space-y-1 md:mx-0 md:items-start md:w-1/2">
						<NuxtLink to="/">
							<LazyTheLogo
								class="w-48"
								lazy-load
							/>
						</NuxtLink>
						<p class="py-2 text-sm text-gray-500">
							{{ $t('footer.logo-subtitle') }}
						</p>
						<div class="flex space-x-8">
							<LazySocialLink
								v-for="link in SOCIAL_LINKS"
								:key="link"
								:link="link"
								:aria-label="link"
								@click="handleClick(link)"
								@mouseenter="handleHover(link)"
							/>
						</div>
					</div>
					<div class="flex justify-between w-full px-2 py-8 space-x-4 text-left md:py-4 md:w-1/2">
						<div
							v-for="category in navLinks"
							:key="category.heading"
							class="max-w-[100px] md:max-w-lg"
						>
							<h3 class="text-base font-semibold text-gray-900 uppercase text-md">
								{{ $rt(category.heading) }}
							</h3>
							<ul
								role="list"
								class="px-0 space-y-1 list-none"
							>
								<li
									v-for="link in category.links"
									:key="link.name"
									class="text-gray-500 hover:text-gray-900"
									@click="handleClick($rt(link.url))"
									@mouseenter="handleHover($rt(link.url))"
								>
									<!-- NOTE: this could be better, I had to drop this in quickly to support different urls for internal and external users-->
									<a
										v-if="link.url === '/account'"
										:href="accountUrl"
									>
										{{ $rt(link.name) }}
									</a>
									<a
										v-else-if="link.isAnchor"
										:href="$rt(link.url)"
									>
										{{ $rt(link.name) }}
									</a>
									<NuxtLink
										v-else
										:to="$rt(link.url)"
									>
										{{ $rt(link.name) }}
									</NuxtLink>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="flex flex-col md:flex-row md:justify-between md:space-x-4">
					<p class="text-sm text-gray-500 md:block">
						{{ $t('footer.copyright') }} &copy; {{ LEGAL_NAME }} {{ year }}. {{ $t('footer.all-rights-reserved') }}. <br class="md:hidden">
						<NuxtLink
							:to="localePath('tos')"
							class="text-red-400"
							@click="handleClick('tos')"
							@mouseenter="handleHover('tos')"
						>
							{{ $t('footer.terms-of-service') }}
						</NuxtLink> {{ $t('and') }}
						<NuxtLink
							:to="localePath('privacy')"
							class="text-red-400"
							@click="handleClick('privacy')"
							@mouseenter="handleHover('privacy')"
						>
							{{ $t('footer.privacy-policy') }}
						</NuxtLink>
					</p>
				</div>
			</div>
		</slot>
	</footer>
</template>

<script defer setup lang="ts">
import { computed, ref, onMounted, nextTick, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import type { FooterNavItems } from '@/types/footer'
import { SOCIAL_LINKS } from '@/constants/footer'
import {
	FOOTER_NAV_CLICK,
	FOOTER_NAV_HOVER,
	LIVE_CHAT_WIDGET_CLICK
} from '@/constants/gtm/events.js'
import type { FooterNavEvent } from '@/types/gtm/events'
import { useTrackEvent } from '@/composables/useEventTracking'
import { useMetrics } from '@/composables/metrics'
import { LEGAL_NAME } from '@/constants/index'
import { useAuth } from '@/composables/useAuth'

const { loggedIn, accountUrl, getAuth } = useAuth()

const route = useRoute()

const { tm } = useI18n({
	useScope: 'global'
})
const localePath = useLocalePath()

const { startTime, pagesVisited, setStartTime, incrementPagesVisited } = useMetrics()

const navLinks = computed(() => {
	return loggedIn.value ? tm('footer.logged-in-nav-links') as FooterNavItems[] : tm('footer.nav-links') as FooterNavItems[]
})

const year = new Date().getFullYear()

function handleClick (href: string) {
	const trackedEvent: FooterNavEvent = {
		event: FOOTER_NAV_CLICK,
		category: 'Footer',
		action: 'click',
		label: 'Footer Nav Click',
		href
	}
	useTrackEvent(trackedEvent)
}
function handleHover (href: string) {
	const trackedEvent: FooterNavEvent = {
		event: FOOTER_NAV_HOVER,
		category: 'Footer',
		action: 'hover',
		label: 'Footer Nav Hover',
		href
	}
	useTrackEvent(trackedEvent)
}

const path = computed(() => {
	return route.path
})
watch(path, () => {
	incrementPagesVisited()
})

onMounted(() => {
	getAuth()
	setStartTime(Date.now())
})
</script>
