import { API_BASE, API_ENDPOINT_ADDRESS, API_ENDPOINT_ADDRESS_COORDINATES, API_ENDPOINT_ADDRESS_PLACE_ID } from '@/constants/index.js'
import { useI18n } from 'vue-i18n'
import { useErrorReporter } from '@/composables/useErrorReporter'
import { isAddressCheckResponseValid } from '@/types/api'
import { useAddressLocalStorage } from '@/composables/useLocalStorage'
import { useRouter } from 'vue-router'
import { mapPackages } from '@/utils/mapPackages'
const { setAddressObject } = useAddressLocalStorage()

const loading = ref(false)
const billables = ref()

export function useProspectAddress () {
	const { t } = useI18n({
		useScope: 'global'
	})
	const router = useRouter()

	const localePath = useLocalePath()
	async function getProspectAddressData (searchTerm: string, zip: string) {
		const { reportError } = useErrorReporter(useBugsnag().notify)
		const formData = {
			address: `${searchTerm}, ${zip}`
		}
		loading.value = true
		return await $fetch(`${API_BASE}${API_ENDPOINT_ADDRESS}`, {
			method: 'POST',
			body: formData,
			onResponse ({ response }) {
				if (!response.ok) {
					router.push({ path: localePath('find-address'), query: { zip, address: searchTerm } })
					const errorMessage = response?._data?.error || response?._data?.errors[0].title
					throw new Error(errorMessage)
				}
				const data = response._data
				billables.value = mapPackages(data?.billables)
				if (isAddressCheckResponseValid(data)) {
					const { addressId = 0, zoneType = 'remote', address = searchTerm, accountFound } = data
					const { status } = setAddressObject(address, addressId, zoneType, billables.value, accountFound)
					if (status?.length) {
						accountFound ? router.push({ path: localePath('already-registered') }) : router.push({ path: localePath('get-fiber'), query: { fiber: status } })
					} else {
						reportError('Zone to Status mapping failed: fiber status is undefined')
					}
				}
				loading.value = false
			}
		}).catch((e) => {
			const errorMessage = `${e} - Address: ${formData.address}`
			reportError(errorMessage)
			loading.value = false
			return e
		})
	}

	async function getProspectAddressWithCoordinates ({ lat, lng }: {lat: number, lng: number}) {
		const { reportError } = useErrorReporter(useBugsnag().notify)
		const formData = {
			lat,
			lon: lng
		}
		loading.value = true
		return await $fetch(`${API_BASE}${API_ENDPOINT_ADDRESS_COORDINATES}`, {
			method: 'POST',
			body: formData,
			onResponse ({ response }) {
				if (!response.ok) {
					const errorMessage = response?._data?.error || response?._data?.errors[0].title
					throw new Error(errorMessage)
				}
				const data = response._data
				billables.value = mapPackages(data?.billables)
				if (isAddressCheckResponseValid(data)) {
					const { addressId = 0, zoneType = 'remote', address, accountFound } = data
					const { status } = setAddressObject(address, addressId, zoneType, billables.value, accountFound)
					if (status?.length) {
						accountFound ? router.push({ path: localePath('already-registered') }) : router.push({ path: localePath('get-fiber'), query: { fiber: status } })
					} else {
						reportError('Zone to Status mapping failed: fiber status is undefined')
					}
				}
			}
		}).catch((e) => {
			const errorMessage = `${e} - Coordinates: ${formData.lat}, ${formData.lon}`
			reportError(errorMessage,
				true,
				{
					title: t('find-address.error'),
					message: t('find-address.no-address')
				}
			)
			return e
		}).finally(() => {
			loading.value = false
		})
	}

	async function getProspectAddressWithPlaceId (placeId: string, zip: string, searchTerm: string) {
		const { reportError } = useErrorReporter(useBugsnag().notify)
		loading.value = true
		return await $fetch(`${API_BASE}${API_ENDPOINT_ADDRESS_PLACE_ID}`, {
			method: 'POST',
			body: { placeId },
			onResponse ({ response }) {
				if (!response.ok) {
					router.push({ path: localePath('find-address'), query: { zip, address: searchTerm } })
					const errorMessage = response?._data?.error || response?._data?.errors[0].title
					throw new Error(errorMessage)
				}
				const data = response._data
				billables.value = mapPackages(data?.billables)
				if (isAddressCheckResponseValid(data)) {
					const { addressId = 0, zoneType = 'remote', address, accountFound } = data
					const { status } = setAddressObject(address, addressId, zoneType, billables.value, accountFound)
					if (status?.length) {
						accountFound ? router.push({ path: localePath('already-registered') }) : router.push({ path: localePath('get-fiber'), query: { fiber: status } })
					} else {
						reportError('Zone to Status mapping failed: fiber status is undefined')
					}
				}
			}
		}).catch((e) => {
			const errorMessage = `${e} - Place ID: ${placeId}`
			reportError(errorMessage)
			return e
		}).finally(() => {
			loading.value = false
		})
	}

	return {
		getProspectAddressData,
		loading,
		billables,
		getProspectAddressWithCoordinates,
		getProspectAddressWithPlaceId
	}
}
