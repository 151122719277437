import { default as aboutfg6tw6Z4yTMeta } from "/home/maverixbroadband.com/mx-front/pages/about.vue?macro=true";
import { default as acceptable_45use_45policyf5vQOw99wxMeta } from "/home/maverixbroadband.com/mx-front/pages/acceptable-use-policy.vue?macro=true";
import { default as index3yzu41MugeMeta } from "/home/maverixbroadband.com/mx-front/pages/admin/index.vue?macro=true";
import { default as already_45registeredXpOFLGyVs3Meta } from "/home/maverixbroadband.com/mx-front/pages/already-registered.vue?macro=true";
import { default as _91article_93ehK4vWEI8lMeta } from "/home/maverixbroadband.com/mx-front/pages/blog/[article].vue?macro=true";
import { default as indexPq2lnOL6LoMeta } from "/home/maverixbroadband.com/mx-front/pages/blog/index.vue?macro=true";
import { default as _91page_93mNIiiY6xQ8Meta } from "/home/maverixbroadband.com/mx-front/pages/blog/page/[page].vue?macro=true";
import { default as business_45internetKGwFs5uf7aMeta } from "/home/maverixbroadband.com/mx-front/pages/business-internet.vue?macro=true";
import { default as careersMQRcMtBpx1Meta } from "/home/maverixbroadband.com/mx-front/pages/careers.vue?macro=true";
import { default as contactxnpFpbzMtNMeta } from "/home/maverixbroadband.com/mx-front/pages/contact.vue?macro=true";
import { default as create_45accountRuN9DoG1dHMeta } from "/home/maverixbroadband.com/mx-front/pages/create-account.vue?macro=true";
import { default as find_45addressUpoRr00pJeMeta } from "/home/maverixbroadband.com/mx-front/pages/find-address.vue?macro=true";
import { default as _91hash_931oSnt3HghGMeta } from "/home/maverixbroadband.com/mx-front/pages/forgot-password/[hash].vue?macro=true";
import { default as indexiiqy7LYH9UMeta } from "/home/maverixbroadband.com/mx-front/pages/forgot-password/index.vue?macro=true";
import { default as get_45fiberzPgo5YYqg5Meta } from "/home/maverixbroadband.com/mx-front/pages/get-fiber.vue?macro=true";
import { default as indexfJMj47u6Q4Meta } from "/home/maverixbroadband.com/mx-front/pages/index.vue?macro=true";
import { default as _91event_93iJi8XO7T9gMeta } from "/home/maverixbroadband.com/mx-front/pages/kiosk/[event].vue?macro=true";
import { default as indexqsHQlhU10mMeta } from "/home/maverixbroadband.com/mx-front/pages/kiosk/index.vue?macro=true";
import { default as late_45paymentsQGdRbMndRqMeta } from "/home/maverixbroadband.com/mx-front/pages/late-payments.vue?macro=true";
import { default as _91linkHash_939O2JkkPjYNMeta } from "/home/maverixbroadband.com/mx-front/pages/link/[linkHash].vue?macro=true";
import { default as loginpGTmsZH2Q8Meta } from "/home/maverixbroadband.com/mx-front/pages/login.vue?macro=true";
import { default as maintenanceto8ywkD51mMeta } from "/home/maverixbroadband.com/mx-front/pages/maintenance.vue?macro=true";
import { default as outagelGSJqFwWfJMeta } from "/home/maverixbroadband.com/mx-front/pages/outage.vue?macro=true";
import { default as indexHNw77rlimAMeta } from "/home/maverixbroadband.com/mx-front/pages/press/index.vue?macro=true";
import { default as privacyJdvcpCWtqMMeta } from "/home/maverixbroadband.com/mx-front/pages/privacy.vue?macro=true";
import { default as residential_45internetNAzD6MmkCzMeta } from "/home/maverixbroadband.com/mx-front/pages/residential-internet.vue?macro=true";
import { default as residential_45services_45agreementWyMXmhk2toMeta } from "/home/maverixbroadband.com/mx-front/pages/residential-services-agreement.vue?macro=true";
import { default as _91area_93wkrwW19YsRMeta } from "/home/maverixbroadband.com/mx-front/pages/service-areas/[area].vue?macro=true";
import { default as indexAexyHejFlZMeta } from "/home/maverixbroadband.com/mx-front/pages/service-areas/index.vue?macro=true";
import { default as tossgLBTSCJexMeta } from "/home/maverixbroadband.com/mx-front/pages/tos.vue?macro=true";
import { default as unactivated_45user0QCvM3A0nyMeta } from "/home/maverixbroadband.com/mx-front/pages/unactivated-user.vue?macro=true";
import { default as install_45processeeTNrzc329Meta } from "/home/maverixbroadband.com/mx-front/pages/widget/install-process.vue?macro=true";
export default [
  {
    name: "about___en",
    path: "/about",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/about.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/about.vue")
  },
  {
    name: "acceptable-use-policy___en",
    path: "/acceptable-use-policy",
    meta: acceptable_45use_45policyf5vQOw99wxMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/acceptable-use-policy.vue")
  },
  {
    name: "acceptable-use-policy___es",
    path: "/es/acceptable-use-policy",
    meta: acceptable_45use_45policyf5vQOw99wxMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/acceptable-use-policy.vue")
  },
  {
    name: "admin___en",
    path: "/admin",
    meta: index3yzu41MugeMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/admin/index.vue")
  },
  {
    name: "admin___es",
    path: "/es/admin",
    meta: index3yzu41MugeMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/admin/index.vue")
  },
  {
    name: "already-registered___en",
    path: "/already-registered",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/already-registered.vue")
  },
  {
    name: "already-registered___es",
    path: "/es/already-registered",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/already-registered.vue")
  },
  {
    name: "blog-article___en",
    path: "/blog/:article()",
    meta: _91article_93ehK4vWEI8lMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/[article].vue")
  },
  {
    name: "blog-article___es",
    path: "/es/blog/:article()",
    meta: _91article_93ehK4vWEI8lMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/[article].vue")
  },
  {
    name: "blog___en",
    path: "/blog",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/index.vue")
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/index.vue")
  },
  {
    name: "blog-page-page___en",
    path: "/blog/page/:page()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/page/[page].vue")
  },
  {
    name: "blog-page-page___es",
    path: "/es/blog/page/:page()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/blog/page/[page].vue")
  },
  {
    name: "business-internet___en",
    path: "/business-internet",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/business-internet.vue")
  },
  {
    name: "business-internet___es",
    path: "/es/business-internet",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/business-internet.vue")
  },
  {
    name: "careers___en",
    path: "/careers",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/careers.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/careers.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    meta: contactxnpFpbzMtNMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    meta: contactxnpFpbzMtNMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/contact.vue")
  },
  {
    name: "create-account___en",
    path: "/create-account",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/create-account.vue")
  },
  {
    name: "create-account___es",
    path: "/es/create-account",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/create-account.vue")
  },
  {
    name: "find-address___en",
    path: "/find-address",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/find-address.vue")
  },
  {
    name: "find-address___es",
    path: "/es/find-address",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/find-address.vue")
  },
  {
    name: "forgot-password-hash___en",
    path: "/forgot-password/:hash()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/[hash].vue")
  },
  {
    name: "forgot-password-hash___es",
    path: "/es/forgot-password/:hash()",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/[hash].vue")
  },
  {
    name: "forgot-password___en",
    path: "/forgot-password",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password___es",
    path: "/es/forgot-password",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/forgot-password/index.vue")
  },
  {
    name: "get-fiber___en",
    path: "/get-fiber",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/get-fiber.vue")
  },
  {
    name: "get-fiber___es",
    path: "/es/get-fiber",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/get-fiber.vue")
  },
  {
    name: "index___en",
    path: "/",
    meta: indexfJMj47u6Q4Meta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    meta: indexfJMj47u6Q4Meta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/index.vue")
  },
  {
    name: "kiosk-event___en",
    path: "/kiosk/:event()",
    meta: _91event_93iJi8XO7T9gMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/[event].vue")
  },
  {
    name: "kiosk-event___es",
    path: "/es/kiosk/:event()",
    meta: _91event_93iJi8XO7T9gMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/[event].vue")
  },
  {
    name: "kiosk___en",
    path: "/kiosk",
    meta: indexqsHQlhU10mMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/index.vue")
  },
  {
    name: "kiosk___es",
    path: "/es/kiosk",
    meta: indexqsHQlhU10mMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/kiosk/index.vue")
  },
  {
    name: "late-payments___en",
    path: "/late-payments",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/late-payments.vue")
  },
  {
    name: "late-payments___es",
    path: "/es/late-payments",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/late-payments.vue")
  },
  {
    name: "link-linkHash___en",
    path: "/link/:linkHash()",
    meta: _91linkHash_939O2JkkPjYNMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/link/[linkHash].vue")
  },
  {
    name: "link-linkHash___es",
    path: "/es/link/:linkHash()",
    meta: _91linkHash_939O2JkkPjYNMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/link/[linkHash].vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/login.vue")
  },
  {
    name: "maintenance___en",
    path: "/maintenance",
    meta: maintenanceto8ywkD51mMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/maintenance.vue")
  },
  {
    name: "maintenance___es",
    path: "/es/maintenance",
    meta: maintenanceto8ywkD51mMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/maintenance.vue")
  },
  {
    name: "outage___en",
    path: "/outage",
    meta: outagelGSJqFwWfJMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/outage.vue")
  },
  {
    name: "outage___es",
    path: "/es/outage",
    meta: outagelGSJqFwWfJMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/outage.vue")
  },
  {
    name: "press___en",
    path: "/press",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/press/index.vue")
  },
  {
    name: "press___es",
    path: "/es/press",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/press/index.vue")
  },
  {
    name: "privacy___en",
    path: "/privacy",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/privacy.vue")
  },
  {
    name: "privacy___es",
    path: "/es/privacy",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/privacy.vue")
  },
  {
    name: "residential-internet___en",
    path: "/residential-internet",
    meta: residential_45internetNAzD6MmkCzMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-internet.vue")
  },
  {
    name: "residential-internet___es",
    path: "/es/residential-internet",
    meta: residential_45internetNAzD6MmkCzMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-internet.vue")
  },
  {
    name: "residential-services-agreement___en",
    path: "/residential-services-agreement",
    meta: residential_45services_45agreementWyMXmhk2toMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-services-agreement.vue")
  },
  {
    name: "residential-services-agreement___es",
    path: "/es/residential-services-agreement",
    meta: residential_45services_45agreementWyMXmhk2toMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/residential-services-agreement.vue")
  },
  {
    name: "service-areas-area___en",
    path: "/service-areas/:area()",
    meta: _91area_93wkrwW19YsRMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/[area].vue")
  },
  {
    name: "service-areas-area___es",
    path: "/es/service-areas/:area()",
    meta: _91area_93wkrwW19YsRMeta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/[area].vue")
  },
  {
    name: "service-areas___en",
    path: "/service-areas",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/index.vue")
  },
  {
    name: "service-areas___es",
    path: "/es/service-areas",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/service-areas/index.vue")
  },
  {
    name: "tos___en",
    path: "/tos",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/tos.vue")
  },
  {
    name: "tos___es",
    path: "/es/tos",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/tos.vue")
  },
  {
    name: "unactivated-user___en",
    path: "/unactivated-user",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/unactivated-user.vue")
  },
  {
    name: "unactivated-user___es",
    path: "/es/unactivated-user",
    component: () => import("/home/maverixbroadband.com/mx-front/pages/unactivated-user.vue")
  },
  {
    name: "widget-install-process___en",
    path: "/widget/install-process",
    meta: install_45processeeTNrzc329Meta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/widget/install-process.vue")
  },
  {
    name: "widget-install-process___es",
    path: "/es/widget/install-process",
    meta: install_45processeeTNrzc329Meta || {},
    component: () => import("/home/maverixbroadband.com/mx-front/pages/widget/install-process.vue")
  }
]