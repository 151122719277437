<template>
	<div
		:class="[
			'bg-mx-navy hero-lengths md:bg-fixed hero-image text-white text-center sticky z-20 pb-1',
			position,
			height
		]"
	>
		<slot
			name="content"
			:show-search="showSearch"
		>
			<div class="max-w-xl mx-auto lg:max-w-2xl">
				<slot name="h1">
					<div v-if="hasTitle">
						<h2
							v-if="h1Elsewhere"
							:class="['px-4 py-2 text-3xl font-semibold uppercase md:text-4xl md:w-full lg:text-5xl',
								isAreaPage ? 'capitalize' : 'uppercase'
							]"
						>
							{{ title }}
						</h2>
						<h1
							v-else
							:class="['px-4 py-2 text-3xl font-semibold uppercase md:text-4xl md:w-full lg:text-5xl',
								isAreaPage ? 'capitalize' : 'uppercase'
							]"
						>
							{{ title }}
						</h1>
					</div>
				</slot>
				<slot name="h2">
					<h2
						v-if="hasH2"
						class="w-4/5 max-w-lg mx-auto -mt-6 text-base font-normal line-clamp-3 md:mt-2"
					>
						{{ h2Text }}
					</h2>
				</slot>
			</div>
		</slot>
		<slot name="h3">
			<h3
				v-if="hasH3"
				class="absolute -translate-x-1/2 left-1/2 bottom-[125px] justify-end w-4/5 mx-auto md:bottom-[62px] text-xs font-normal min-h-[40px] md:text-sm line-clamp-2 my-0"
			>
				{{ h3Text }}
			</h3>
		</slot>
		<div
			v-if="showSearch"
			class="absolute w-11/12 -translate-x-1/2 left-1/2 md:w-full bottom-8"
		>
			<SignupAddressChecker
				id="hero-address-checker"
				is-hero-form
				class="mx-auto"
				:button-text="t('address-checker.hero-button-text')"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import type { HeroContentProps } from '@/components/home/types'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const { t } = useI18n()

const props = defineProps({
	showSearch: {
		type: Boolean,
		default: false
	},
	content: {
		type: Object as PropType<HeroContentProps>,
		required: true
	},
	h1Elsewhere: {
		type: Boolean,
		default: false
	}
})
const { showSearch, content } = toRefs(props)

const title = computed(() => {
	return content.value?.title || ''
})

const h2Text = computed(() => {
	return content.value?.subtitle || ''
})

const h3Text = computed(() => {
	return content.value?.description || ''
})

const hasTitle = computed(() => {
	return !!title.value?.length
})

const hasH2 = computed(() => {
	return !!h2Text.value?.length
})

const hasH3 = computed(() => {
	return !!h3Text.value?.length
})

const isAreaPage = computed(() => {
	return route?.meta?.isAreaPage
})

const position = computed(() => {
	return '-top-[162px] xs:-top-[204px] md:-top-[164px]'
})

const height = computed(() => {
	if (isAreaPage.value) {
		return 'xs:h-96 h-[375px] md:h-[325px]'
	}
	if (showSearch.value) {
		return 'xs:h-96 h-[360px] md:h-[345px]'
	}
	return ''
})
</script>
