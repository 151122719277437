<template>
	<ul
		v-if="isSearchActive"
		class="pt-2 text-black bg-white divide-y divide-gray-200 rounded-b-lg shadow-lg"
	>
		<li
			v-for="(result, index) in resultList"
			:key="index"
			:class="[
				'px-2 py-3 hover:bg-gray-100 whitespace-nowrap text-ellipsis overflow-hidden flex items-center space-x-2 shrink-0',
				{ 'bg-gray-100': activeSearchItem === index },
				{ 'rounded-b-lg': index === resultList.length - 1 }
			]"
			@mousedown="handleSetSearchTerm(result.desc, result.id, index)"
		>
			<IconPin class="text-black shrink-0" />
			<span>
				{{ result?.desc }}
			</span>
		</li>
	</ul>
</template>

<script setup lang="ts">
import { toRefs } from 'vue'
const props = defineProps({
	resultList: {
		type: Array as PropType<{desc: string, id: string}[]>,
		required: true
	},
	isSearchActive: {
		type: Boolean,
		required: true
	},
	activeSearchItem: {
		type: Number,
		required: true
	}
})

const { resultList, isSearchActive, activeSearchItem } = toRefs(props)

const emit = defineEmits([ 'setSearchTerm' ])

const handleSetSearchTerm = (searchTerm: string, id: string, index: number) => {
	emit('setSearchTerm', searchTerm, id, index)
}

</script>
